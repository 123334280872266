// import logo from './logo.svg';
// import './App.css';
import React, { Component, useState } from 'react';
import Terminal from  'terminal-in-react';
import stringTable from 'string-table'

// var initSqlJs = window.initSqlJs;
function TerminalSql(props) {
  const prompt_symbol_colors = {"default":"green","current":"red"}
  const [ command , setCommand ] = useState("");             
  const [ commandSts , setCommandSts ] = useState("complete");
  

  var detect_end = (cmd)=>{
    let s = cmd.toString().replace(' ' , '') 
    let lastlettr = s.at(-1);

    if( lastlettr === ";"){
      return true;
    }
    if( s === ""){
      return true;
    }
    return false;
    
  }


  const exec = ( cmd, print, runCommand )=>{
    
    if(detect_end(cmd)){
      setCommandSts("complete")
      runCommand("complete")
      
      let current_command = `${command}`
      for(let a in cmd){
          console.log(`${cmd[a]}`)
          current_command = `${current_command} ${cmd[a]}`
        }
      

      setCommand("")
      let o = props.func(current_command)
      

      if( o.err === null){

          let out_res = []
          let k = ""
          let v = ""
          console.log(o.res)
          for(let a in o.res)
          { 
            
              
             
              for(let row in o.res[a].values)
              {
                out_res[row] = []
                for(let data in o.res[a].values[row])
                {
                   
                  out_res[row][o.res[a].columns[data]]=o.res[a].values[row][data]
                }
                
                
              }

           
          }
        
        return stringTable.create(out_res)
      }
      else{
        console.log(o.err)
        return "   "+o.err.toString()

      }
    }
    else
    {
      let tmp_cmd =""
      for(let a in cmd){
        tmp_cmd = `${tmp_cmd} ${cmd[a]}`
      }
      setCommand(`${command} ${tmp_cmd}`)
      setCommandSts("incomplete")
      runCommand("incomplete")
      
    }
    
  }

   class Plugin {
    static displayName = '';
    static version = '1.0.0';
    static defaultData = '';
  
    static commands = {};
    static descriptions = {};
    static shortcuts = {};
  
    constructor(api, config = {}) {
      this.api = api;
      this.config = config;
      this.commands = {
        "":()=>{return "llll"},
        complete:()=>{this.api.setPromptSymbol("tin-sql>>");},
        incomplete:()=>{this.api.setPromptSymbol("  #──────>");}
      };
      this.descriptions = {};
      this.shortcuts = {};
  
      this.updateApi = (newApi) => { this.api = newApi; };
      this.getPublicMethods = () => ({});
      this.readStdOut = () => true;
    }
  }


  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      
      <Terminal plugins={[
        Plugin
      ]} startState="maximised" promp={"white"} hideTopBar={true} outputColor={"#c2c2c2"} color={"white"}  promptSymbol={" tin-sql >> "} commandPassThrough={(cmd , ll , lp) => exec(cmd , ll , lp) }/>
    </div>
  );
}

export default TerminalSql;
